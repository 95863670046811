import React from "react";
import "./GoogleAdsSection.css";

const GoogleAdsSection = () => {
    return (
        <div className="google-ads-container">
            <div className="google-ads-content">
                <img src="/Graph.webp" alt="Google Ads" className="google-ads-image" />
                <div className="google-ads-text">
                    <h2>Boost Your Business With GOOGLE ADS</h2>
                    <p>
                        Our certified specialists will help you create and manage effective Google Ads campaigns
                        that target the right audience, increase website traffic, and generate more leads and sales
                        for your business. Contact us today to learn more about our Google Ads services.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default GoogleAdsSection;
