import { useEffect, useState } from 'react';
import './OurWork.css';

const OurWork = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const projects = [
        { src: "/workA.webp", alt: "Project 1" },
        { src: "/workB.webp", alt: "Project 2" },
        { src: "/workC.webp", alt: "Project 3" },
        { src: "/workD.webp", alt: "Project 4" },
        { src: "/workE.webp", alt: "Project 5" },
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % projects.length);
        }, 3000);

        return () => clearInterval(interval);
    }, [projects.length]);

    return (
        <div className="our-work-container">
            <div className="our-work">
                <h2 className="section-title">OUR <span>Work</span></h2>
                <p className="section-description">
                    Welcome to our web development showcase! Explore a collection of our most innovative projects, websites, and success stories.
                    At Saptam Technologies, we believe in delivering seamless, dynamic, and user-friendly web solutions. Here’s your backstage pass to experience the impact we’ve made for our clients through cutting-edge web development.
                </p>
                <div className="work-gallery">
                    {projects.map((project, index) => (
                        <img
                            key={index}
                            src={project.src}
                            alt={project.alt}
                            className={
                                index === currentIndex
                                    ? "active"
                                    : index === (currentIndex - 1 + projects.length) % projects.length
                                    ? "prev"
                                    : index === (currentIndex + 1) % projects.length
                                    ? "next"
                                    : ""
                            }
                        />
                    ))}
                </div>
                <div className="get-website-container">
  <button
    className="get-website-button"
    onClick={() => window.open("https://wa.me/918306060312?text=Hello%20There,%0AThanks%20for%20contacting%20us.%0AHow%20may%20I%20help%20you?", "_blank")}
  >
    Get Your Website
  </button>
</div>

            </div>
        </div>
    );
};

export default OurWork;
