import { useState, useEffect } from "react";
import "./SEOPopup.css";

const SEOPopup = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => setShowPopup(true), 15000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (success) {
      const closeTimer = setTimeout(() => setShowPopup(false), 1000);
      return () => clearTimeout(closeTimer);
    }
  }, [success]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    if (!email.trim() || !/\S+@\S+\.\S+/.test(email)) {
      setError("Please enter a valid email.");
      setLoading(false);
      return;
    }

    try {
      const formData = new FormData();
      formData.append("email", email.trim());
      if (website.trim()) {
        formData.append("website", website.trim());
      }

      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbyjauOGF5xm7fxDFQQdGvUEqOhiLnBJJcg_vWfbKy-kFAVX-2VI6pBe2MJ6FQzk0I2v/exec",
        { method: "POST", body: formData, mode: "cors" }
      );

      const result = await response.json();

      if (!result.success) {
        throw new Error(result.error || "Subscription failed");
      }

      setSuccess(true);
      setEmail("");
      setWebsite("");
    } catch (err) {
      setError(err.message || "Failed to subscribe. Try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {showPopup && (
        <div className="seo-popup-overlay" aria-hidden={!showPopup}>
          <div className="seo-popup-container">
            <button 
              className="seo-popup-close-icon" 
              onClick={() => setShowPopup(false)}
              aria-label="Close popup"
              tabIndex="0"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>

            <div className="seo-popup-left">
              <p className="seo-discount-text">Subscribe now & claim your free business growth consultation!</p>
            </div>

            <div className="seo-popup-right">
              <h2 className="seo-popup-title">Enter your details below.</h2>
              {success ? (
                <p className="seo-popup-success">✅ Thank you for subscribing! Your SEO report will be sent soon.</p>
              ) : (
                <form onSubmit={handleSubmit}>
                  <input 
                    type="email" 
                    placeholder="Enter email address" 
                    className="seo-popup-input"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={loading}
                  />
                  <input 
                    type="url" 
                    placeholder="Enter website URL (if available)" 
                    className="seo-popup-input"
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                    disabled={loading}
                  />
                  <button type="submit" className="seo-popup-button" disabled={loading}>
                    {loading ? "Subscribing..." : "Subscribe"}
                  </button>
                  {error && <p className="seo-popup-error">{error}</p>}
                </form>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SEOPopup;