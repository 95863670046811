import React from "react";
import { reviews } from "./reviewsData";
import "./Reviews.css"; // Import CSS file

const Reviews = () => {
    return (
        <div className="reviews-section">
            <h2 className="reviews-title">EXCELLENT</h2>
            <div className="stars">⭐⭐⭐⭐⭐</div>
            <p className="reviews-subtitle">Based On 100 Reviews</p>

            <div className="reviews-slider">
                <div className="reviews-container">
                    {reviews.concat(reviews).map((review) => ( // Duplicated to ensure continuous sliding
                        <div key={review.id} className="review-card">
                            <div className="review-header">
                                <div className="review-user">
                                    <p className="review-name">{review.name}</p>
                                    <p className="review-date">{review.date}</p>
                                </div>
                                {review.platform === "Google" && (
                                    <span className="review-platform">G</span>
                                )}
                            </div>
                            <div className="review-stars">⭐⭐⭐⭐⭐</div>
                            <p className="review-text">{review.review}</p>
                        </div>
                    ))}
                </div>

                <div className="reviews-container">
                    {reviews.concat(reviews).map((review) => ( // Duplicated to ensure continuous sliding
                        <div key={review.id} className="review-card">
                            <div className="review-header">
                                <div className="review-user">
                                    <p className="review-name">{review.name}</p>
                                    <p className="review-date">{review.date}</p>
                                </div>
                                {review.platform === "Google" && (
                                    <span className="review-platform">G</span>
                                )}
                            </div>
                            <div className="review-stars">⭐⭐⭐⭐⭐</div>
                            <p className="review-text">{review.review}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Reviews;
