import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { blogs } from "../pages/Blogs"; // Import blog data
import "./OurBlog.css"; // Import CSS

const OurBlog = () => {
    const navigate = useNavigate(); // Initialize navigate function

    const handleClick = (slug) => {
        navigate(`/blog/${slug}`); // Navigate to the blog page using slug
    };

    return (
        <div className="blog-section">
            <h2 className="blog-title">Our Blogs</h2>
            <div className="blog-container">
                {blogs.map((blog) => (
                    <div key={blog.id} className="blog-card" onClick={() => handleClick(blog.slug)}>
                        <div className="blog-image-container">
                            <img src={blog.image} alt={blog.title} className="blog-image" />
                        </div>
                        <div className="blog-content">
                            <h3 className="blog-heading">{blog.title}</h3>
                            <p className="blog-preview">{blog.preview}</p>
                            <div className="blog-footer">
                                <span className="blog-date">{blog.Date}</span>
                                <span className="blog-read-time">2 min read</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {/* Know More Button */}
            <div className="blog-button-container">
                <button className="blog-button" onClick={() => navigate("/blog")}>
                    Know More
                </button>
            </div>
        </div>
    );
};

export default OurBlog;
