import React from "react";
import "./SaptamServices.css";

const Services = () => {
  const services = [
    {
      title: "Free Consultation",
      description:
        "Unlock expert advice for free! If you're tired of underperforming websites and lackluster marketing strategies, let's talk about how we can help you achieve real success.",
      icon: <img src="/consultation.webp" alt="Free Consultation" />, 
    },
    {
      title: "Develop A Roadmap",
      description:
        "Our strategies are designed specifically for you. Instead of one-size-fits-all marketing packages, we create a personalized roadmap that directly aligns with your business objectives.",
      icon: <img src="/develop.webp" alt="Develop A Roadmap" />, 
    },
    {
      title: "Execution of Strategy",
      description:
        "We don't just strategize – we deliver. Using data-driven insights, we build websites and craft marketing campaigns that attract genuine customers.",
      icon: <img src="/strategy.webp" alt="Execution of Strategy" />, 
    },
  ];

  return (
    <div className="saptam-services-container">
      {services.map((service, index) => (
        <div key={index} className="saptam-service-card">
          <div className="saptam-service-icon">{service.icon}</div>
          <h2 className="saptam-service-title">{service.title}</h2>
          <p className="saptam-service-description">{service.description}</p>
        </div>
      ))}
    </div>
  );
};

export default Services;

