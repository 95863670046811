import React, { useEffect } from 'react';
import "./SEO.css";

function GraphicDesigning() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 

  return (
    <div className="seo-services-container">
      <div className="section">
        <h1>Search Engine Optimization</h1>
        <p>
          At Saptam Technologies, we understand that getting found online is essential to growing your business. 
          SEO (Search Engine Optimization) is the cornerstone of a successful digital strategy, helping your website rank higher 
          in search engine results, drive organic traffic, and convert visitors into loyal customers.
          Our team of SEO experts leverages data, creativity, and cutting-edge tools to ensure your business stays ahead of the competition 
          in the ever-changing digital landscape.
        </p>
      </div>

      <div className="section">
        <h2>Why SEO is Crucial for Your Business</h2>
        <p>📊 SEO by the Numbers:</p>
        <ul>
          <li>68% of all online experiences begin with a search engine (BrightEdge).</li>
          <li>Websites listed on the first page of Google capture 91.5% of traffic, while lower pages receive less than 5%.</li>
          <li>SEO leads have a 14.6% conversion rate, compared to just 1.7% for outbound marketing (HubSpot).</li>
        </ul>
        <p>🌐 Long-Term Benefits:</p>
        <p>Unlike paid ads, SEO offers sustainable growth by driving consistent organic traffic over time.</p>
        <p>🎯 Cost-Effective:</p>
        <p>SEO is one of the most cost-effective marketing strategies, offering an impressive ROI for businesses of all sizes.</p>
      </div>

      <div className="section">
        <h2>Our Comprehensive SEO Services</h2>
        <p>1. Keyword Research and Analysis: 
          We identify high-intent, relevant keywords that align with your business goals and target audience. By understanding what your customers are searching for, we optimize your website to match their needs.
        </p>
        <p>2. On-Page SEO:
          Our on-page optimization services ensure every page of your website is search engine-friendly. This includes:
          <ul>
            <li>Optimizing titles, meta descriptions, and headers.</li>
            <li>Improving content structure and keyword placement.</li>
            <li>Ensuring mobile responsiveness and fast page load times.</li>
          </ul>
        </p>
        <p>3. Technical SEO:
          We address technical aspects of your website to ensure search engines can crawl and index it effectively:
          <ul>
            <li>Fixing broken links and errors.</li>
            <li>Optimizing site architecture and XML sitemaps.</li>
            <li>Implementing structured data and schema markup.</li>
          </ul>
        </p>
        <p>4. Off-Page SEO:
          Build credibility and authority with off-page strategies, including:
          <ul>
            <li>High-quality backlink acquisition.</li>
            <li>Social signals and influencer outreach.</li>
            <li>Local citations and directory submissions.</li>
          </ul>
        </p>
        <p>5. Local SEO:
          For businesses targeting specific locations, we optimize Google My Business (GMB) profiles, local keywords, and citations to improve your presence in local searches.
        </p>
        <p>6. Content Marketing: 
          Quality content is the backbone of SEO. We create blogs, articles, infographics, and videos designed to engage your audience while improving your search rankings.
        </p>
        <img src="/graphics1.webp" alt="Ads" style={{ width: '100%', height: 'auto', marginTop: '20px' }} />
        {/* <img src="/googleandcredit.webp" alt="googleandcredit" style={{ width: '100%', height: 'auto', marginTop: '20px' }} /> */}
      </div>

      <div className="section">
        <h2>Our Proven SEO Process</h2>
        <p>1. In-Depth Website Audit:
          We start by conducting a thorough audit to identify strengths, weaknesses, and opportunities in your website's current SEO strategy.
        </p>
        <p>2. Customized Strategy Development:
          Based on our findings, we create a tailored SEO plan that aligns with your goals, industry, and competition.
        </p>
        <p>3. Implementation and Optimization:
          Our team implements on-page, off-page, and technical SEO tactics, continuously optimizing your website for maximum impact.
        </p>
        <p>4. Performance Tracking and Reporting:
          We monitor key performance indicators (KPIs), such as keyword rankings, traffic, and conversions, providing detailed reports to keep you informed.
        </p>
        {/* <img src="/piktochart.webp" alt="googleandcredit" style={{ width: '100%', height: 'auto', marginTop: '20px' }} /> */}
        <img src="/graphics2.webp" alt="Ads2" style={{ width: '100%', height: 'auto', marginTop: '20px' }} />
      </div>

      <div className="section">
        <h2>Why Choose Saptam Technologies for SEO?</h2>
        <ul>
          <li>🧠 Experienced SEO Specialists: Our team stays ahead of SEO trends and algorithm updates, ensuring your website remains competitive.</li>
          <li>📞 Transparent Communication: We believe in clear communication. You’ll receive regular updates and actionable insights into your website’s performance.</li>
          <li>🌍 Holistic Approach: We combine technical expertise, creative content, and data-driven strategies to deliver comprehensive SEO solutions.</li>
          <li>📈 Proven Results: Our clients have experienced significant improvements in search rankings, traffic, and conversions.</li>
        </ul>
      </div>

      <div className="section">
        <h2>💡 Client Success Story:</h2>
        <p>A B2B client saw a 120% increase in organic traffic within six months of implementing our SEO strategy, resulting in a 3x increase in qualified leads.</p>
      </div>

      <div className="section">
        <h2>The Benefits of Partnering with Saptam Technologies for SEO</h2>
        <ul>
          <li>Higher Rankings: Appear on the first page of Google for the keywords that matter most to your business.</li>
          <li>Increased Traffic: Drive relevant, high-quality traffic to your website.</li>
          <li>Better User Experience: Enjoy faster, mobile-friendly websites that keep visitors engaged.</li>
          <li>Enhanced Brand Authority: Build trust and credibility with improved search visibility.</li>
        </ul>
      </div>

      <div className="last">
        <h2>Ready to Dominate SEO?</h2>
        <p>
          At Saptam Technologies, we’re passionate about helping businesses unlock the full potential of SEO. Whether you’re a small startup or an established enterprise, our tailored strategies are designed to deliver tangible results.
        </p>
        <p><strong style = {{color: '#2980b9'}}>📩 Contact us today to start your SEO journey!</strong></p>
        <a
          href="https://wa.me/918306060312"
          target="_blank"
          rel="noopener noreferrer"
          className="quotation-button"
        >
          Get Quotation
        </a>
      </div>
    </div>
  );
}

export default GraphicDesigning;
