// Portfolio.js
import React from 'react';
import './Portfolio.css';

function Portfolio() {
  return (
    <div className="portfolio-container">
      <div className="portfolio-title">
        <h2>Businesses</h2>
        <p>We've Worked With</p>
      </div>
      <div className="portfolio-logos">
        <a href="/" target="_blank" rel="noopener noreferrer" className="logo-card dark-card">
          <img src="/momaa.webp" alt="Momaa Foods" />
          <p>Momaa Foods</p>
        </a>
        <a href="https://shreesawariyasevasansthan.com/" target="_blank" rel="noopener noreferrer" className="logo-card light-card">
          <img src="/shre.webp" alt="Shree Sawariya Seva Sansthan" />
          <p>Shree Sawariya Seva Sansthan</p>
        </a>
        <a href="https://rabbittone.com/" target="_blank" rel="noopener noreferrer" className="logo-card dark-card">
          <img src="/rabbitone.webp" alt="RabitTone" />
          <p>RabitTone</p>
        </a>
        <a href="/" target="_blank" rel="noopener noreferrer" className="logo-card light-card">
          <img src="/kukiudaan.webp" alt="KukiUdaan" />
          <p>Kuki Udaan & Holidays</p>
        </a>
        <a href="https://gitjaipur.com/" target="_blank" rel="noopener noreferrer" className="logo-card dark-card">
          <img src="/gitLogo.webp" alt="GIT" />
          <p>Global Institute of Technology, Jaipur</p>
        </a>
      </div>

      <div className="quotation-now-container">
        <button
          className="quotation-now-button"
          onClick={() => window.open("https://wa.me/918306060312?text=Hello%20There,%0AThanks%20for%20contacting%20us.%0AHow%20may%20I%20help%20you?", "_blank")}
        >
          Get Quotation Now
        </button>
      </div>


    </div>
  );
}

export default Portfolio;
