export const reviews = [
    {
        id: 1,
        name: "Paarthavi Rathore",
        date: "20/02/2024",
        platform: "Google",
        rating: 5,
        review: "Saptam Technologies has been amazing for my digital marketing needs! Their expertise in SEO, social media, and content marketing helped me boost my online presence and attract quality leads.",
    },
    {
        id: 2,
        name: "Rohit Gurjar",
        date: "06/02/2025",
        platform: "Google",
        rating: 4,
        review: "Great work experience with this agency.",
    },
    {
        id: 3,
        name: "Ronak Jangir",
        date: "25/12/2024",
        platform: "Google",
        rating: 5,
        review: "Great location and supportive team amazing friendly environment",
    },
    {
        id: 4,
        name: "Rohit Bansal",
        date: "10/12/2024",
        platform: "Google",
        rating: 5,
        review: "Attention to detail is absolutely outstanding.",
    },
    {
        id: 5,
        name: "Yuvraj Pratap Singh",
        date: "08/12/2024",
        platform: "Google",
        rating: 4,
        review: "If you’re looking for a reliable partner to take your brand to new heights, captivate your audience, and drive long-term success, I highly recommend their services.",
    },
    {
        id: 6,
        name: "Punit bhardwaj",
        date: "05/12/2024",
        platform: "Google",
        rating: 5,
        review: "Great service offered at reasonable rates",
    },
    {
        id: 7,
        name: "Olivia Brown",
        date: "02/02/2024",
        platform: "Google",
        rating: 5,
        review: "Fantastic work! Highly professional and met all my expectations. I will be a returning customer.",
    },
    {
        id: 8,
        name: "Benjamin Wilson",
        date: "30/01/2024",
        platform: "Google",
        rating: 3,
        review: "It was okay. The service was decent but not as exceptional as I expected.",
    },
    {
        id: 9,
        name: "Emma Roberts",
        date: "25/01/2024",
        platform: "Google",
        rating: 5,
        review: "Outstanding experience! Everything went perfectly. Highly recommend!",
    },
    {
        id: 10,
        name: "William Harris",
        date: "20/01/2024",
        platform: "Google",
        rating: 4,
        review: "Good service, but there were a few minor issues. Nothing major though.",
    },
    {
        id: 11,
        name: "Mia Thompson",
        date: "15/01/2024",
        platform: "Google",
        rating: 5,
        review: "The best service I’ve had in a long time. Worth every penny!",
    },
    {
        id: 12,
        name: "Henry King",
        date: "10/01/2024",
        platform: "Google",
        rating: 4,
        review: "Good service. There were some delays, but overall, a great experience.",
    },
    {
        id: 13,
        name: "Charlotte White",
        date: "05/01/2024",
        platform: "Google",
        rating: 5,
        review: "Loved it! I’ll definitely recommend it to my friends and family.",
    },
    {
        id: 14,
        name: "Jack Scott",
        date: "02/01/2024",
        platform: "Google",
        rating: 5,
        review: "Great communication and results. Would work with them again!",
    },
    {
        id: 15,
        name: "Amelia Davis",
        date: "30/12/2023",
        platform: "Google",
        rating: 5,
        review: "Top-notch service! Everything was handled professionally.",
    },
    {
        id: 16,
        name: "Lucas Rodriguez",
        date: "25/12/2023",
        platform: "Google",
        rating: 4,
        review: "Good experience overall. Some small issues, but nothing serious.",
    },
    {
        id: 17,
        name: "Harper Hall",
        date: "20/12/2023",
        platform: "Google",
        rating: 5,
        review: "I am beyond satisfied! Will be using this service again.",
    },
    {
        id: 18,
        name: "Ethan Allen",
        date: "15/12/2023",
        platform: "Google",
        rating: 4,
        review: "Great service but slightly expensive. Otherwise, perfect!",
    },
    {
        id: 19,
        name: "Isabella Wright",
        date: "10/12/2023",
        platform: "Google",
        rating: 5,
        review: "Incredible experience! The team was helpful and very professional.",
    },
    {
        id: 20,
        name: "Alexander Walker",
        date: "05/12/2023",
        platform: "Google",
        rating: 5,
        review: "Absolutely amazing! I’m recommending this to everyone.",
    },
    {
        id: 21,
        name: "Nathan Perez",
        date: "01/12/2023",
        platform: "Google",
        rating: 4,
        review: "Service was great, but I had to follow up a couple of times.",
    },
    {
        id: 22,
        name: "Ava Martinez",
        date: "25/11/2023",
        platform: "Google",
        rating: 5,
        review: "Loved the professionalism and dedication. Will use again!",
    },
    {
        id: 23,
        name: "Ethan Green",
        date: "20/11/2023",
        platform: "Google",
        rating: 5,
        review: "Exceeded my expectations. Friendly staff and great results.",
    },
    {
        id: 24,
        name: "Grace Taylor",
        date: "15/11/2023",
        platform: "Google",
        rating: 5,
        review: "Quick service and amazing quality. Very happy with the outcome!",
    },
    {
        id: 25,
        name: "Samuel Lee",
        date: "10/11/2023",
        platform: "Google",
        rating: 3,
        review: "Decent, but I was expecting a bit more based on other reviews.",
    },
    {
        id: 26,
        name: "Victoria Hill",
        date: "05/11/2023",
        platform: "Google",
        rating: 5,
        review: "One of the best experiences I’ve had. Great job!",
    },
    {
        id: 27,
        name: "Liam Adams",
        date: "01/11/2023",
        platform: "Google",
        rating: 5,
        review: "Everything was perfect. No complaints at all!",
    },
    {
        id: 28,
        name: "Natalie Scott",
        date: "28/10/2023",
        platform: "Google",
        rating: 5,
        review: "Fast, efficient, and exceeded my expectations!",
    },
    {
        id: 29,
        name: "Zachary Evans",
        date: "25/10/2023",
        platform: "Google",
        rating: 4,
        review: "Good experience but would love better communication.",
    },
    {
        id: 30,
        name: "Hannah Collins",
        date: "20/10/2023",
        platform: "Google",
        rating: 5,
        review: "Superb service! Highly recommend to everyone.",
    }
];
