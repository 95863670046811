import React, { useEffect, useState } from 'react';
import './ContactUs.css';
import { MdEmail, MdLocationOn, MdPhone } from 'react-icons/md';

function ContactUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    service: '',
    category: '',
    url: '',
    message: ''
  });

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateInput = (data) => {
    for (const key in data) {
      if (['name', 'email', 'phone', 'message'].includes(key)) {
        if (!data[key].trim()) return `${key} cannot be empty or spaces`;
      }
    }
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    const validationError = validateInput(formData);
    if (validationError) {
      setError(validationError);
      setLoading(false);
      return;
    }

    try {
      let formDataToSend = new FormData();
      Object.keys(formData).forEach(key => {
        let value = formData[key].trim() || 'N/A';
        formDataToSend.append(key, value);
      });

      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbzoftenEts2bVrfBHjDgeskv1De8oIhZgbf38R07bhPSGrNQergCRiskuykaSr2cYbp/exec",
        { method: 'POST', body: formDataToSend, mode: 'cors' }
      );
      
      if (!response.ok) throw new Error(`Error: ${response.statusText}`);

      setSuccess(true);
      setFormData({ name: '', email: '', phone: '', service: '', category: '', url: '', message: '' });
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="contact-us-container">
      <h2>Contact <span>Us</span></h2>
      <form onSubmit={handleSubmit} className="contact-form">
        <div className="form-left">
          <div className="form-group-left"><h3>Pleased to Connect – How Can We Assist You?</h3></div>
          <div className="form-group bordered"><p>Call Us:</p><strong><span className="icon-text"><MdPhone className="icon" />+91 8306060312</span></strong></div>
          <div className="form-group bordered"><p>Send Mail:</p><a href="mailto:info@saptamtech.com" className="icon-text"><MdEmail className="icon" />info@saptamtech.com</a></div>
          <div className="form-group bordered"><p>Our Office Location:</p><strong><span className="icon-text"><MdLocationOn className="icon" />Sector 1, Malviya Nagar, Jaipur, India, 302017</span></strong></div>
        </div>
        <div className="form-right">
          <div className="form-group"><input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Enter your full name*" required /></div>
          <div className="form-group"><input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Enter your email address*" required /></div>
          <div className="form-group"><input type="tel" name="phone" value={formData.phone} onChange={handleChange} placeholder="Enter your contact number*" required /></div>
          <div className="form-group">
            <select name="service" value={formData.service} onChange={handleChange} required>
              <option value="">Select a service*</option>
              <option value="Website Development">Website Development</option>
              <option value="Google & Facebook Ads">Google & Meta Ads</option>
              <option value="Social Media Handling">Social Media Handling</option>
              <option value="Search Engine Optimization">Search Engine Optimization</option>
              <option value="Ecommerce Development">E-commerce Development</option>
              <option value="Answer Engine Optimisation">Answer Engine Optimisation</option>
            </select>
          </div>
          {['Website Development', 'Ecommerce Development'].includes(formData.service) && (
            <div className="form-group">
              <input type="text" name="category" value={formData.category} onChange={handleChange} placeholder="Enter category*" />
            </div>
          )}
          {['Google & Facebook Ads', 'Social Media Handling', 'Search Engine Optimization', 'Answer Engine Optimisation'].includes(formData.service) && (
            <div className="form-group">
              <input type="url" name="url" value={formData.url} onChange={handleChange} placeholder="Enter Website URL*" />
            </div>
          )}
          <div className="form-group"><textarea name="message" value={formData.message} onChange={handleChange} placeholder="Write your message here*" required></textarea></div>
          <div className="submit-container"><button type="submit" className="submit-btn" disabled={loading}>{loading ? 'Submitting...' : 'Submit'}</button></div>
        </div>
      </form>
      {success && <Modal onClose={() => setSuccess(false)} />}
      {error && <p className="error-message">{error}</p>}
    </div>
  );
}

const Modal = ({ onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Thank You!</h2>
        <p>Your message has been successfully sent. Our team will get back to you soon.</p>
        <button className="ok-btn" onClick={onClose}>OK</button>
      </div>
    </div>
  );
};

export default ContactUs;
